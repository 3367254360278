<template>
  <div
    class="category-card"
    :class="{ hover: hover }"
    @click="hover = true"
    @mouseover="onMouseOver"
    @mouseleave="onMouseLeave"
    v-click-outside="clickOutside"
  >
    <div class="image-wrapper" :class="{ hashover: links.length === 2 }">
      <media-element :media="image" />
      <div class="overlay">
        <base-link
          class="tile md"
          v-for="(link, index) in links"
          :key="index"
          :linkdata="link.data"
          @click="makeClick"
          :prevent="!hover && links.length > 1"
        >
          {{ link.data.label }}
        </base-link>
      </div>
    </div>
    <div class="title">
      <base-link class="md" v-if="links.length <= 1" :linkdata="links[0].data">
        {{ title }}
      </base-link>
      <div class="md" v-else>{{ title }}</div>
    </div>
    <div class="links lt" v-if="links.length > 1">
      <div class="link" v-for="(link, index) in links" :key="index">
        <base-link :linkdata="link.data">
          {{ link.data.label }}
        </base-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    image: {
      type: Object,
      required: true
    },
    links: {
      type: Array,
      default: () => []
    },
    title: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      hover: false
    }
  },
  methods: {
    clickOutside(e) {
      if (this.hover) {
        this.hover = false
      }
    },
    makeClick() {
      if (this.hover) {
      }
    },
    onMouseOver() {
      setTimeout(() => {
        this.hover = true
      }, 0)
    },
    onMouseLeave() {
      this.hover = false
    }
  }
}
</script>

<style lang="scss">
.category-card {
  width: 100%;
  max-width: 400px;

  @include media-breakpoint-down(sm) {
    margin-bottom: 1rem;
  }

  .image-wrapper {
    position: relative;

    .media-element {
      width: 100%;
      img {
        transform: scale3d(1, 1, 1);
        transition: transform ease 0.4s;
      }
    }

    .overlay {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: rgba(51, 51, 51, 0.2);
      display: flex;
      opacity: 0;
      // visibility: hidden;
      transition: 0.2s ease-in-out all;

      .tile {
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        transition: 0.2s ease-in-out all;
      }
    }
  }

  .title {
    margin-top: 18px;
    font-weight: 500;
    .base-link.md span {
      font-weight: 500;
    }
  }
  .links {
    margin-top: 3px;
  }
  .link {
    position: relative;
    display: inline-block;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    font-weight: 300;

    &:after {
      position: absolute;
      top: 10%;
      right: 0rem;
      height: 70%;
      width: 1px;
      background-color: #000;
      content: '';
    }
    &:first-child {
      padding-left: 0;
    }
    &:last-child {
      padding-right: 0;
      &:after {
        display: none;
      }
    }
  }

  &.hover {
    .image-wrapper {
      img {
        transform: scale3D(1.05, 1.05, 1);
      }

      &.hashover {
        .overlay {
          opacity: 1;
          visibility: visible;
          .tile {
            &:hover {
              text-decoration: none;
              background: rgba(51, 51, 51, 0.2);
            }
          }
        }
      }
    }
  }

  @media (hover: none) {
    &.hover {
      .image-wrapper {
        img {
          transform: scale3D(1.05, 1.05, 1);
        }

        &.hashover {
          .overlay {
            opacity: 1;
            visibility: visible;
            .tile {
              position: relative;
              span {
                position: relative;
                z-index: 20;
                pointer-events: auto;
              }
              &:hover {
                pointer-events: none;
                text-decoration: none;
                background: rgba(51, 51, 51, 0.2);
              }
            }
          }
        }
      }
    }
  }
}
</style>
