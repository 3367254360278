<template>
  <div class="product-card" :class="{ unavailable: isOutOfStock }">
    <div
      class="img-wrapper"
      @mouseenter="quickbuyVisible = true"
      @mouseleave="quickbuyVisible = false"
    >
      <div class="wrapper">
        <nuxt-link :to="productURL(product)">
          <div class="img-wrapper__text">
            <div
              class="product-is-on-sale"
              v-if="product.variants[0].compareAtPrice !== null"
              v-text="$t('shop.sale')"
            />
            <!-- <div
              class="product-is-new"
              v-if="product.isNew"
              v-text="labels.new"
            /> -->
          </div>
          <div class="aspect-wrapper">
            <shopify-image
              v-if="product.variants[0]"
              :image="product.variants[0].image"
            />
            <shopify-image
              v-if="!product.variants[0]"
              :image="product.images[0]"
            />
          </div>
        </nuxt-link>
      </div>
      <quick-buy
        v-if="isDesktop && !isOutOfStock"
        :product="product"
        :visible="quickbuyVisible"
      />
    </div>
    <nuxt-link :to="productURL(product)">
      <div class="product-meta">
        <div class="product-info">
          <span class="product-brand">{{ product.vendor }}</span>
          <span class="product-title">{{ product.title }}</span>
          <div class="product-price">
            <span
              class="old-price"
              v-if="product.variants[0].compareAtPrice !== null"
            >
              {{ priceFormat(product.variants[0].compareAtPrice) }}&nbsp;
            </span>

            <span
              class="price"
              :class="{ sale: product.variants[0].compareAtPrice !== null }"
            >
              {{ priceFormat(product.variants[0].price) }}
            </span>
          </div>
        </div>
        <div class="product-info-right">
          <!-- <div class="product-variants" v-html="variants" /> -->
          <div
            v-if="isOutOfStock"
            v-text="$t('shop.sold')"
            class="product-not-in-stock"
          />
        </div>
      </div>
    </nuxt-link>
  </div>
</template>

<script>
import QuickBuy from '~/components/shop/QuickBuy'
export default {
  components: {
    QuickBuy
  },
  props: {
    product: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      quickbuyVisible: false,
      addToCartVisible: false
    }
  },
  computed: {
    variants() {
      if (Array.isArray(this.product.variants)) {
        if (this.product.variants.length > 1) {
          if (this.product.variants.length > 3) {
            return `+ 3 ${this.$t('shop.variants')}`
          } else {
            return `${this.product.variants.length} ${this.$t('shop.variants')}`
          }
        }
        return '&nbsp;'
      }
      return ''
    },
    isDesktop() {
      return this.$breakpoints.lMd // Is Large than md include md
    },
    isOutOfStock() {
      return !this._.find(this.product.variants, { available: true })
    }
  },
  methods: {
    priceFormat(price) {
      return new Intl.NumberFormat('de-DE', {
        style: 'currency',
        currency: 'EUR'
      }).format(price)
    },
    productURL(product) {
      if (product.anchor && product.anchor !== 0) {
        return {
          path: this.localePath(`/product/${product.handle}/`),
          hash: 'variant-' + product.anchor.toString()
        }
      } else {
        return { path: this.localePath(`/product/${product.handle}/`) }
      }
    }
  }
}
</script>

<style lang="scss">
$red-price: #ed6f72;
$greyed-out: #a4a2a2;

.product-card {
  a {
    display: block;
    text-decoration: none;
  }
  .img-wrapper {
    margin-bottom: 28px;
    position: relative;

    &:hover {
      .aspect-wrapper .media-element img {
        transform: scale3d(1.05, 1.05, 1);
      }
    }

    .aspect-wrapper {
      position: relative;
      width: 100%;
      padding-top: 115.1364764268%;

      overflow: hidden;
      .media-element {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        img {
          height: 100%;
          transform: scale3d(1, 1, 1);
          transition: transform ease 0.4s;
        }
      }
    }

    &__text {
      position: absolute;
      top: 10px;
      left: 10px;
      z-index: 1;
      font-size: 0.8rem;
      letter-spacing: 0.2em;

      @include media-breakpoint-up(sm) {
        font-size: 1rem;
        letter-spacing: 0.3em;
      }

      .product-is-on-sale {
        color: $red-price;
        font-weight: 500;
      }
      .product-is-new {
        margin-top: 0.25rem;
        font-weight: 500;
      }
    }
    img {
      display: block;
      height: auto;
      width: 100%;
    }
  }
  .product-meta {
    display: flex;
    flex-flow: column nowrap;
    @include media-breakpoint-up(sm) {
      flex-flow: row nowrap;
    }

    .product-info {
      flex: auto;
      letter-spacing: 0.5px;
      line-height: 1.5;

      .product-brand {
        font-weight: 500;
        letter-spacing: 1px;
      }
      .product-price {
        display: block;

        .old-price {
          position: relative;
          &:after {
            position: absolute;
            top: 44%;
            left: 0;
            transform: translateY(-50%);
            height: 0.1rem;
            width: 95%;
            background: $red-price;
            content: '';
          }
        }
        .price.sale {
          color: $red-price;
        }
      }

      &-right {
        flex: auto;
        display: grid;

        @include media-breakpoint-up(sm) {
          text-align: right;
        }

        .product-variants {
          grid-row: 1;
        }
        .product-not-in-stock {
          grid-row: 2;
          color: $red-price;
          align-self: flex-end;
        }
      }
    }
  }

  &.unavailable {
    .product-meta {
      color: $greyed-out;

      .product-not-in-stock {
        color: $red-price;
      }
    }
    .price-sale,
    .price,
    .product-is-new {
      color: $greyed-out !important;
    }
    .price.crossed::after {
      background: $greyed-out !important;
    }
  }
}
</style>
