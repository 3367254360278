<template>
  <b-container
    fluid
    class="filter__wrapper"
    :class="{ 'is-not-sticky': !isSticky }"
    ref="stickyFilter"
    v-sticky
    :key="stickyFilterOffs.top"
    sticky-offset="stickyFilterOffs"
    sticky-z-index="12"
    on-stick="checkSticky"
  >
    <b-row>
      <b-col cols="12">
        <div class="filter__inner">
          <div
            class="burger__wrapper"
            @click.prevent="
              $filter.open({
                id: uid,
                products: products,
                nav: links
              })
            "
            :class="{ collapsed: $store.state.ui.filterOpen }"
          >
            <div class="burger">
              <div class="line" />
              <div class="line" />
              <div class="line" />
            </div>
            <p class="filter-counter">
              Filter
              <span
                v-if="
                  filterActive && $store.state.filter.activeFilter.length > 0
                "
                >({{ $store.state.filter.activeFilter.length }})</span
              >
            </p>
          </div>
          <div class="filter-items" v-if="filterActive">
            <a
              href="#"
              class="filter-item"
              @click.prevent="removeFilter(item)"
              v-for="(item, index) in filterArray"
              :key="index"
              >{{ item.value }}</a
            >
          </div>
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
export default {
  components: {},
  props: {
    uid: Number,
    products: {
      type: Array,
      default: () => {}
    },
    links: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      stickyFilterOffset: {
        top: 75
      },
      isSticky: false
    }
  },
  computed: {
    filterActive() {
      // ONLY ACTIVE IF FILTER HAS BEEN OPENED ONCE AND MATCHES COMPONENT ID
      return (
        this.$store.state.filter.filterData &&
        this.$store.state.filter.filterData.id === this.uid
      )
    },
    stickyFilter() {
      return this.$refs.stickyFilter
    },
    lMd() {
      return this.$breakpoints.lMd
    },
    stickyFilterOffs() {
      return this.stickyFilterOffset
    },
    filterArray() {
      const x = {}
      const filter = []
      // to avoid mutating the $store array we make a clone
      const storeFilter = JSON.parse(
        JSON.stringify(this.$store.state.filter.activeFilter)
      )

      storeFilter.forEach((ele) => {
        if (x[ele.type] === undefined) {
          x[ele.type] = []
          x[ele.type].push(ele)
        } else {
          x[ele.type].push(ele)
        }
      })

      for (const key in x) {
        if (x[key].length > 1) {
          filter.push({
            value: this.$t(`shop.filter.${key}`) + ` (${x[key].length})`,
            count: x[key].length,
            children: x[key]
          })
        } else {
          filter.push(x[key][0])
        }
      }
      return filter
    }
  },
  watch: {
    lMd: {
      handler(newVal, oldVal) {
        if (!newVal) {
          this.stickyFilterOffset.top = 52
        } else {
          this.stickyFilterOffset.top = 74
        }
      },
      immediate: true
    }
  },
  methods: {
    checkSticky(data) {
      this.isSticky = data.sticked
    },
    removeFilter(item) {
      if (item.children) {
        item.children.forEach((el) => {
          this.$filter.remove(el)
        })
      } else {
        this.$filter.remove(item)
      }
    }
  }
}
</script>

<style lang="scss">
.filter__wrapper {
  position: relative;
  height: 52px;
  background-color: $white;
  top: 0;
  display: flex;
  align-items: center;

  &.is-not-sticky {
    position: relative !important;
  }

  @include media-breakpoint-up(sm) {
    height: 62px;
  }

  .filter__inner {
    @include media-breakpoint-up(sm) {
      padding: 0 42px;
    }

    .burger__wrapper {
      display: inline-block;
      cursor: pointer;
      padding-top: 5px;
      &.collapsed {
        .burger {
          .line {
            &:nth-child(1) {
              transform: translateY(8px);
            }
            &:nth-child(3) {
              transform: translateY(-8px);
            }
          }
        }
      }
      .burger {
        display: inline-block;
        height: auto;

        .line {
          display: block;
          height: 2px;
          background-color: $black;
          margin-bottom: 4px;
          transform: translateY(0);
          transition: transform ease 0.3s, opacity ease 0.3s;
          @include media-breakpoint-up(sm) {
            margin-bottom: 6px;
          }
          &:nth-child(1) {
            width: 28px;
            @include media-breakpoint-up(sm) {
              width: 34px;
            }
          }
          &:nth-child(2) {
            width: 18px;
            @include media-breakpoint-up(sm) {
              width: 24px;
            }
          }
          &:nth-child(3) {
            width: 8px;
            @include media-breakpoint-up(sm) {
              width: 14px;
            }
            margin-bottom: 0;
          }
        }
      }
      .filter-counter {
        margin: 0;
        display: inline-block;
        margin-left: 20px;
        letter-spacing: 1.1px;
        text-transform: uppercase;
        vertical-align: text-bottom;
        font-weight: 500;
        span {
          letter-spacing: 1.1px;
          text-transform: uppercase;

          font-weight: 500;
        }
      }
    }
    .filter-items {
      display: inline-block;
      margin-left: 32px;
      transform: translateY(-3px);
      .filter-item {
        display: inline-block;
        margin-right: 16px;

        &:hover {
          text-decoration: line-through;
        }
      }
    }
  }
}
</style>
