<template>
  <base-section class="section sticky-header-section">
    <main-header
      :is-in-content="true"
      :logo-hidden="hideLogo"
      ref="header"
      :fixed="false"
    />
  </base-section>
</template>

<script>
import MainHeader from '~/components/main/MainHeader'
export default {
  name: 'StickyHeader',
  components: {
    MainHeader
  },
  props: {
    fields: Object
  },
  data() {
    return {
      visible: true,
      hideLogo: true
    }
  },
  computed: {
    isMobile() {
      return this.$breakpoints.sMd
    }
  },
  watch: {
    visible() {
      this.$store.commit('ui/showMainHeader', !this.visible)
    }
  },
  beforeMount() {
    this.$store.commit('ui/showMainHeader', false)
    this.$store.commit('ui/setContentTopPadding', false)
  },
  mounted() {
    document.addEventListener('scroll', this.isElementInViewport)
    window.addEventListener('resize', this.isElementInViewport)
  },
  beforeDestroy() {
    document.removeEventListener('scroll', this.isElementInViewport)
    window.removeEventListener('resize', this.isElementInViewport)
    this.$store.commit('ui/showMainHeader', true)
    this.$store.commit('ui/setContentTopPadding', true)
  },
  methods: {
    isElementInViewport() {
      const rect = this.$refs.header.$el.getBoundingClientRect()
      if (rect.top > 0) {
        this.visible = true
        if (!this.isMobile) {
          rect.top < 100 ? (this.hideLogo = false) : (this.hideLogo = true)
        } else {
          rect.top < 70 ? (this.hideLogo = false) : (this.hideLogo = true)
        }
      } else {
        this.visible = false
      }
    }
  }
}
</script>
<style lang="scss">
.sticky-header-section {
  .main-header {
    transform: translateY(0);
  }
}
</style>
