<template>
  <base-section id="products" class="section product-grid-section">
    <product-filter
      :uid="_uid"
      :products="allVariants"
      :links="{
        title: fields.filter_navigation_title,
        links: fields.filter_navigation
      }"
      v-if="hasFilter"
    />
    <b-container>
      <b-row class="mx-1">
        <b-col
          :md="md"
          :cols="cols"
          v-for="product in products"
          :key="product.ahandle"
          class="py-2 px-1 py-sm-3 px-md-2"
        >
          <product-card :product="product" />
        </b-col>
        <b-col
          :md="md"
          :cols="cols"
          v-for="ghost in ghostElements"
          :key="ghost.id"
        >
        </b-col>
      </b-row>
    </b-container>
    <!-- <product-pagination
      v-show="pageCount > 1"
      :count="pageCount"
      v-model="currentPage"
    /> -->
  </base-section>
</template>

<script>
import { uniq as _uniq } from 'lodash'
import ProductCard from '~/components/ProductCard'
import ProductFilter from '~/components/ProductFilter'
import ProductPagination from '~/components/product/ProductPagination'
export default {
  components: {
    ProductCard,
    ProductFilter,
    ProductPagination
  },
  props: {
    fields: Object
  },
  data() {
    return {
      filter: {},
      cols: '6',
      md: '4',
      optionMatches: this.$store.state.filter.optionsMatches,
      productsPerPage: 12,
      currentPage: 0,
      hasFilter: false
    }
  },
  computed: {
    filterActive() {
      // ONLY ACTIVE IF FILTER HAS BEEN OPENED ONCE AND MATCHES COMPONENT ID
      return (
        this.$store.state.filter.filterData &&
        this.$store.state.filter.filterData.id === this._uid
      )
    },
    products() {
      if (this.filterActive) {
        return this.$store.state.filter.filterProducts
      } else {
        return this.allVariants
      }
    },
    paginated() {
      const paged = this._.chain(this.products)
        .drop(this.productsPerPage * this.currentPage)
        .take(this.productsPerPage)
        .value()

      return paged
    },
    pageCount() {
      return Math.ceil(this.products.length / this.productsPerPage)
    },
    allVariants() {
      const variants = []
      this.fields.collection.products.forEach((product, pIndex) => {
        const pRef = {}
        product.variants.forEach((variant, vIndex) => {
          if (!pRef[variant.image.id]) {
            const cProduct = this._.clone(product)
            cProduct.ahandle = cProduct.handle + '#' + vIndex
            cProduct.anchor = vIndex
            cProduct.variants = [variant]
            variants.push(cProduct)
            pRef[variant.image.id] = cProduct
          } else {
            pRef[variant.image.id].variants.push(variant)
          }
        })
      })

      let colors = []
      let brands = []
      let sizes = []

      variants.forEach((cProduct, vIndex) => {
        cProduct.filter = this.getFilterOptions(cProduct)

        cProduct.filter.color.forEach((item, index) => {
          colors.push(item)
        })

        cProduct.filter.brand.forEach((item, index) => {
          brands.push(item)
        })

        cProduct.filter.size.forEach((item, index) => {
          sizes.push(item)
        })
      })

      colors = _uniq(colors)
      brands = _uniq(brands)
      sizes = _uniq(sizes)

      if (colors.length > 1 || brands.length > 1 || sizes.length > 1) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.hasFilter = true
      }

      console.log('Filter: ', this.filter)

      return variants
    },
    ghostElements() {
      const arr = []
      const x = this.allVariants.length % (12 / parseInt(this.md))
      if (x > 0) {
        for (let i = 0; i <= 12 / parseInt(this.md) - x; i++) {
          arr.push({})
        }
      }
      return arr
    }
  },
  methods: {
    getFilterOptions(cProduct) {
      const filter = {}
      this.$store.state.filter.filterOptions.forEach((optionType, index) => {
        filter[optionType] = []
        if (optionType === 'brand' && cProduct.vendor) {
          filter[optionType].push(cProduct.vendor)
        } else if (this.optionMatches[optionType]) {
          filter[optionType] = this.getOptions(cProduct, optionType)
        }
      })
      return filter
    },
    getOptions(cProduct, optionType) {
      const options = []
      cProduct.variants.forEach((variant, index) => {
        variant.selectedOptions.forEach((option, oindex) => {
          if (
            this.optionMatches[optionType].includes(option.name.toLowerCase())
          ) {
            if (optionType === 'color') {
              if (option.value.includes('/')) {
                const subColors = this._.compact(option.value.split('/'))
                subColors.forEach((subColor, scindex) => {
                  options.push(this.getColorGroup(subColor))
                })
              } else {
                options.push(this.getColorGroup(option.value))
              }
            } else {
              options.push(option.value)
            }
          }
        })
      })
      return this._.compact(this._.uniq(options))
    },
    getColorGroup(color) {
      const group = this._.find(
        this.$store.state.site.options.filter.colors,
        this._.flow(
          this._.property('matches'),
          this._.partialRight(this._.some, { name: color })
        )
      )
      if (group) {
        return group.name
      } else {
        console.log('Color ', color, 'not found')
        return ''
      }
    }
  }
}
</script>

<style lang="scss">
.product-grid-section {
  padding: 7vw 0 125px;
  @include media-breakpoint-up(md) {
    padding: 2vw 0 250px;
  }
}
</style>
