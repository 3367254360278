<template>
  <base-section
    class="section breadcrumbs"
    :class="{ hasNoHeader: !hasNewsAlert }"
    ref="stickyBreadcrumb"
    v-sticky
    :key="stickyBreadcrumbOffs.top"
    sticky-offset="stickyBreadcrumbOffs"
    sticky-z-index="10"
  >
    <nav>
      <template v-for="(item, index) in fields.links">
        <nuxt-link
          v-if="item.link && item.link != $route.path"
          :key="index"
          :to="item.link"
          >{{ item.label }}</nuxt-link
        >
        <div v-else class="no-link" :key="index">{{ item.label }}</div>
      </template>
    </nav>
  </base-section>
</template>

<script>
export default {
  props: {
    fields: Object
  },
  data() {
    return {
      stickyBreadcrumbOffset: {
        top: 75
      },
      isOnTop: false,
      topVal: 0
    }
  },
  computed: {
    stickyBreadcrumb() {
      return this.$refs.stickyBreadcrumb
    },
    lMd() {
      return this.$breakpoints.lMd
    },
    stickyBreadcrumbOffs() {
      return this.stickyBreadcrumbOffset
    },
    hasNewsAlert() {
      return this.topVal > 75
    }
  },
  watch: {
    lMd: {
      handler(newVal, oldVal) {
        if (!newVal) {
          this.stickyBreadcrumbOffset.top = 52
        } else {
          this.stickyBreadcrumbOffset.top = 74
        }
      },
      immediate: true
    }
  },
  mounted() {
    this.topVal = this.$refs.stickyBreadcrumb.$el.getBoundingClientRect().top
  }
}
</script>

<style lang="scss">
.breadcrumbs {
  transition: padding-top $shift-animation-easing $shift-animation-duration,
    transform $shift-animation-easing $shift-animation-duration;
  &.hasNoHeader {
    .activeOffset & {
      padding-top: 50px;
    }
  }
  nav {
    padding: 17px 0;
    height: 52px;
    margin: 0 23px;

    @include media-breakpoint-up(sm) {
      padding: 24px 0;
      height: 70px;
      margin: 0 50px;
    }
    a,
    .no-link {
      display: inline-block;
      line-height: 1;
      margin-left: 8px;

      &:before {
        background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='4px' height='9px' viewBox='0 0 4 9' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3C!-- Generator: Sketch 63.1 (92452) - https://sketch.com --%3E%3Ctitle%3Earrow%3C/title%3E%3Cdesc%3ECreated with Sketch.%3C/desc%3E%3Cg id='Symbols' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='Breadcrum' transform='translate(-196.000000, -28.000000)'%3E%3Cg%3E%3Crect id='Rectangle' fill='%23FFFFFF' x='0' y='-4.54747351e-13' width='1440' height='62'%3E%3C/rect%3E%3Ctext id='Baby-Clothes-Boys-Ov' font-family='Helvetica' font-size='16' font-weight='normal' line-spacing='19'%3E%3Ctspan x='101' y='35' fill='%23B4B4B4'%3EBaby Clothes%3C/tspan%3E%3Ctspan x='196.164062' y='35' fill='%231C0F0C'%3E Boys Overview%3C/tspan%3E%3C/text%3E%3Cg id='arrow' transform='translate(193.000000, 28.000000)' stroke='%23B4B4B4'%3E%3Cpolyline id='Path' transform='translate(3.500000, 4.500000) rotate(-315.000000) translate(-3.500000, -4.500000) ' points='0.8 1.8 5.6 2.4 6.2 7.2'%3E%3C/polyline%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
        content: '';
        display: inline-block;
        height: 8px;
        margin-right: 8px;
        width: 6px;
      }
      &:first-child {
        // color: #999;
        margin-left: 0;
        &:before {
          display: none;
        }
      }

      &.no-link {
        color: #999;
      }
    }
  }
}
</style>
