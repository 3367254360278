<template>
  <base-section class="section related-teaser-section">
    <b-container>
      <b-row v-if="fields.headline">
        <b-col cols="12">
          <h4 :class="['teaser-title', 'text-' + fields.headline_alignment]">
            {{ fields.headline }}
          </h4>
        </b-col>
      </b-row>
      <b-row class="related-teaser-wrapper">
        <b-col
          cols="6"
          sm="6"
          md="4"
          v-for="(teaser, i) in fields.teaser"
          :key="i"
          class="related-teaser"
        >
          <base-link
            :key="teaser.link_group.label"
            :linkdata="teaser.link_group"
          >
            <figure class="img" role="group">
              <div class="img-wrapper">
                <!-- <img :src="teaser.image" alt="" /> -->
                <media-element :media="teaser.teaserimage" />
              </div>
              <media-element
                class="logo"
                v-if="teaser.logo"
                :media="teaser.logo"
              />
              <!-- <img
                :src="teaser.logo.url"
                
                
                alt=""
              /> -->
            </figure>
            <figcaption class="headline md" v-if="teaser.headline">
              {{ teaser.headline }}
            </figcaption>
            <figcaption
              class="copy"
              v-if="teaser.copy"
              v-html="teaser.copy"
            ></figcaption>
          </base-link>
        </b-col>
      </b-row>
    </b-container>
  </base-section>
</template>

<script>
export default {
  props: {
    fields: Object
  },
  data() {
    return {}
  }
}
</script>

<style lang="scss">
section.related-teaser-section {
  padding-top: 80px;
  padding-bottom: 140px;
  .teaser-title {
    padding-bottom: 16px;
  }
  .related-teaser-wrapper {
    display: flex;
    justify-content: center;
    .related-teaser {
      padding-bottom: 8px;
      &:nth-child(even) {
        padding-left: 4px;
      }
      &:nth-child(odd) {
        padding-right: 4px;
      }
      &:nth-child(odd):last-child {
        padding-left: 4px;
        max-width: calc(50% - 20px);
      }
      @include media-breakpoint-up(sm) {
        padding-bottom: 1rem;
        &:nth-child(even) {
          padding-left: 0.5rem;
          padding-right: 0.5rem;
        }
        &:nth-child(odd) {
          padding-left: 0.5rem;
          padding-right: 0.5rem;
        }
        &:nth-child(odd):last-child {
          padding-left: 0.5rem;
          max-width: initial;
        }
      }
      a {
        text-decoration: none;
        &:hover {
          .img .img-wrapper .media-element img {
            transform: scale3d(1.05, 1.05, 1);
          }
        }
        .img {
          position: relative;
          display: block;
          width: 100%;
          margin: 0 0;
          // margin-bottom: 24px;
          // margin-bottom: 0;
          .img-wrapper {
            .media-element {
              img {
                display: block;
                height: auto;
                width: 100%;
                max-width: 100%;
                transform: scale3d(1, 1, 1);
                transition: transform ease 0.4s;
              }
            }
          }
          .logo {
            margin: 0 auto;
            position: absolute;
            bottom: 20px;
            left: 0;
            right: 0;
            height: auto;
            max-width: 80px;
            width: 100%;
            @include media-breakpoint-up(sm) {
              max-width: 200px;
              bottom: 40px;
            }
          }
        }
        figcaption {
          &.headline {
            margin-bottom: 0;
          }
          &:nth-last-child(1) {
            margin-bottom: 4px;
          }
        }
      }
    }
  }
}
</style>
