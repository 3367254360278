<template>
  <base-section
    class="section slider-teaser-section"
    :style="{ backgroundColor: fields.bg_color }"
  >
    <b-container>
      <b-row>
        <b-col md="4" cols="auto">
          <h4 class="mb-4 mb-sm-3">{{ fields.headline }}</h4>
        </b-col>
        <b-col md="8" cols="auto" class="upper-text">
          <p
            v-for="(slide, index) in fields.slides"
            :key="index"
            class="teaser-slide-text upper full-width"
            :style="`transform: translateX(${index * -100}%)`"
            :class="{ animate: currentIndex === index }"
          >
            {{ slide.text }}
          </p>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12">
          <div class="slider">
            <client-only>
              <swiper
                :options="sliderOptions"
                ref="slider"
                @change="slideChange"
                class="mb-md-0 mb-3"
              >
                <swiper-slide v-for="slide in fields.slides" :key="slide.id">
                  <div
                    class="teaser-slide-wrapper px-md-1"
                    :class="{ 'full-width': slide.fullWidth }"
                  >
                    <base-link
                      :linkdata="slide.link"
                      :force-show-if-null="true"
                    >
                      <media-element
                        class="slide-image"
                        :media="slide.visual"
                        :locked-aspect-ratio="
                          slide.visual.type === 'image' && isMobile
                        "
                        :style="imgAspectRatio(slide.visual)"
                      />
                      <div
                        v-if="slide.subtitle !== ''"
                        class="subtitle mt-3 mb-3 mt-md-4 mb-md-0"
                      >
                        {{ slide.subtitle }}
                      </div>
                    </base-link>
                  </div>
                </swiper-slide>
              </swiper>
            </client-only>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <p
            class="teaser-slide-text bottom"
            :class="{ animate: currentIndex === oldIndex }"
          >
            {{ slideText }}
          </p>
          <div
            v-if="fields.slides.length > 5"
            class="teaser-slide__slide-counter"
            v-text="slideCounter"
          />
          <Paginations
            v-show="fields.slides.length <= 5 && fields.slides.length > 1"
            :pag-class="pagi"
            class="dark"
          />
        </b-col>
      </b-row>
    </b-container>
    <div
      class="mouse-follower-area"
      ref="mouseFollowerArea"
      :class="{
        active:
          mouseFollowerActive && !isTabletAndBelow && fields.slides.length > 1,
        'is-mobile': isTabletAndBelow,
        'single-slide': fields.slides.length <= 1
      }"
    >
      <div
        class="left"
        @mouseover="mouseFollower('prev')"
        @mouseout="hideMouseFollower"
        @click="slidePrev"
      />
      <div class="center" />
      <div
        class="right"
        @mouseover="mouseFollower('next')"
        @mouseout="hideMouseFollower"
        @click="slideNext"
      />
      <div
        class="arrow-wrapper"
        ref="hijack"
        :style="arrowStyle"
        :class="[arrowDir, { click: arrowClicked }]"
      >
        <arrow-up height="35px" />
      </div>
    </div>
  </base-section>
</template>

<script>
// import Arrow from '~/assets/svg/arrow.svg?inline'
import arrowUp from '~/assets/svg/arrow-up.svg?inline'
import Paginations from '~/components/slider/Paginations'

export default {
  components: {
    arrowUp,
    Paginations
  },
  props: {
    fields: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      currentIndex: 0,
      oldIndex: 0,
      pagi: 'teaser-slider-pagination',
      sliderOptions: {
        updateOnWindowResize: true,
        spaceBetween: 200,
        autoHeight: true,
        slidesPerView: 1,
        watchOverflow: true,
        observer: true,
        observeParents: true,
        pagination: {
          el: '.teaser-slider-pagination',
          clickable: true
        },
        on: {
          init: () => {}
        }
      },
      mousePos: {
        x: 0,
        y: 0
      },
      arrowDir: 'prev',
      mouseFollowerActive: false,
      arrowClicked: false,
      isTabletAndBelow: false
    }
  },
  computed: {
    swiper() {
      return this.$refs.slider.swiper()
    },
    slideText() {
      if (this.oldIndex !== this.currentIndex) {
        return this.fields.slides[this.oldIndex].text
      } else {
        return this.fields.slides[this.currentIndex].text
      }
    },
    slideCounter() {
      return `${this.currentIndex + 1} / ${this.fields.slides.length}`
    },
    arrowStyle() {
      return {
        top: this.mousePos.y + 'px',
        left: this.mousePos.x + 'px',
        background: this.fields.bg_color
      }
    },
    isMobile() {
      return !this.$breakpoints.lSm
    }
  },
  mounted() {
    this.$refs.mouseFollowerArea.addEventListener(
      'mousemove',
      this.updateMousePos
    )
    window.addEventListener('resize', this.updateIsTabletAndBelow)
    this.updateIsTabletAndBelow()
  },
  beforeDestroy() {
    this.$refs.mouseFollowerArea.removeEventListener(
      'mousemove',
      this.updateMousePos
    )
    window.removeEventListener('resize', this.updateIsTabletAndBelow)
  },
  methods: {
    slideNext() {
      this.swiper.isEnd ? this.swiper.slideTo(0) : this.swiper.slideNext()
      this.arrowClicked = true
      const that = this
      setTimeout(() => {
        that.arrowClicked = false
      }, 100)
    },
    slidePrev() {
      this.swiper.isBeginning
        ? this.swiper.slideTo(this.fields.slides.length - 1)
        : this.swiper.slidePrev()
      this.arrowClicked = true
      const that = this
      setTimeout(() => {
        that.arrowClicked = false
      }, 100)
    },
    slideChange(e) {
      const that = this
      this.currentIndex = this.swiper.realIndex
      setTimeout(() => {
        that.oldIndex = that.currentIndex
      }, 250)
    },
    updateMousePos(e) {
      this.mousePos.x = e.pageX
      this.mousePos.y = e.offsetY
    },
    mouseFollower(dir) {
      this.mouseFollowerActive = true
      this.arrowDir = dir
    },
    hideMouseFollower() {
      this.mouseFollowerActive = false
    },
    updateIsTabletAndBelow() {
      this.isTabletAndBelow = window.matchMedia(
        '(min-width: 0px) and (max-width: 1024px)'
      ).matches
    },
    imgAspectRatio(visual) {
      if (visual.type === 'image' && this.isMobile) {
        return {
          'padding-top': (visual.image.height / visual.image.width) * 100 + '%'
        }
      }
    }
  }
}
</script>

<style lang="scss">
.slider-teaser-section {
  background-color: #fdf8f1;
  padding: 80px 0 95px;
  position: relative;

  .swiper-container {
    overflow: visible;
    width: 100%;
    height: auto;
    @include media-breakpoint-up(sm) {
      height: 450px;
      width: auto;
    }
    @include media-breakpoint-up(lg) {
      height: 666px;
      width: auto;
    }
    .swiper-wrapper {
      width: 100%;
      height: auto;
      @include media-breakpoint-up(sm) {
        height: 100% !important;
        width: auto;
      }
      .swiper-slide {
        height: auto;
        width: 100%;
        @include media-breakpoint-up(sm) {
          height: 100%;
          width: auto;
        }
        .teaser-slide-wrapper {
          width: 100%;
          height: auto;
          margin: 0 auto;
          overflow: hidden;
          @include media-breakpoint-up(sm) {
            height: 100%;
            text-align: center;
          }
          @include media-breakpoint-up(xl) {
            overflow: visible;
          }

          &.full-width {
            max-width: 100% !important;
            text-align: center;
          }
          a,
          link {
            display: block;
            width: auto;
            height: 100%;
            .slide-image img {
              width: 100%;
              height: auto;
              @include media-breakpoint-up(sm) {
                height: 100%;
                width: auto;
              }
            }
          }

          .subtitle {
            font-weight: 500;
          }
        }
      }
    }
  }

  .upper-text {
    display: flex;
  }

  .teaser-slide-text {
    opacity: 0;
    visibility: hidden;
    // TODO: ADDING ANIMATION

    transition: 0.2s all cubic-bezier(0.455, 0.03, 0.515, 0.955);
    &.animate {
      opacity: 1;
      visibility: visible;
      @include media-breakpoint-up(sm) {
        transition-delay: 0.2s;
      }
    }
    &.full-width {
      min-width: 100%;
    }

    &.upper {
      display: none;
      @include media-breakpoint-up(md) {
        display: block;
        margin-bottom: 36px;
      }
    }
    &.bottom {
      display: block;
      @include media-breakpoint-up(md) {
        display: none;
      }
    }
  }

  .teaser-slide__slide-counter {
    display: flex;
    font-size: 1.5rem;
    align-items: center;
    justify-content: center;
    padding-top: 0.75rem;

    @include media-breakpoint-up(md) {
      font-size: 1.875rem;
      justify-content: flex-end;
    }
  }

  a:hover {
    text-decoration: none;
  }

  .mouse-follower-area {
    position: absolute;
    overflow: hidden;
    display: flex;
    z-index: 9;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    opacity: 0;
    transition: 0.2s opacity ease-in-out;
    pointer-events: none;

    &.active {
      opacity: 1;
      cursor: none;
    }

    &.is-mobile {
      display: none;
    }

    .left,
    .center,
    .right {
      flex: auto;
      height: 100%;
      pointer-events: auto;
    }

    .center {
      pointer-events: none;
    }
    &.single-slide {
      .left,
      .center,
      .right {
        pointer-events: none;
      }
    }

    .arrow-wrapper {
      position: absolute;
      top: 0;
      left: 0;
      pointer-events: none;
      transform: translate(-50%, -50%) rotate(90deg);
      border-radius: 50%;
      width: 70px;
      height: 70px;
      background-color: #fff;
      transition: box-shadow ease 0.4s;
      box-shadow: 0 2px 17px 0 rgba(0, 0, 0, 0.05);
      transition: 0.15s transform ease-in-out;

      svg {
        display: inline-block;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) scale3d(1, 1, 1);
        transition: 0.15s all ease-in-out;
      }

      &.prev {
        transform: translate(-50%, -50%) rotate(-90deg);
        &.click {
          transform: translate(-50%, -50%) rotate(-90deg) scale3d(0.9, 0.9, 0.9);
        }
      }
      &.click {
        transform: translate(-50%, -50%) rotate(90deg) scale3d(0.9, 0.9, 0.9);
        svg {
          transform: translate(-50%, -50%) scale3d(0.9, 0.9, 0.9);
        }
      }
    }
  }
}
</style>
