<template>
  <base-section
    class="section big-teaser-section"
    :style="{ backgroundColor: fields.bg_color }"
  >
    <b-container>
      <b-row align-h="center">
        <b-col cols="12" sm="8">
          <template v-if="fields.has_link">
            <base-link :key="fields.link.label" :linkdata="fields.link">
              <media-element :media="fields.teaserimage" />
              <h1 class="bold">{{ fields.link.label }}</h1>
            </base-link>
          </template>
          <template v-else>
            <media-element :media="fields.teaserimage" />
            <h1 class="bold">{{ fields.headline }}</h1>
          </template>
        </b-col>
      </b-row>
    </b-container>
  </base-section>
</template>

<script>
export default {
  props: {
    fields: Object
  }
}
</script>

<style lang="scss">
.big-teaser-section {
  background: #f3dcda;
  padding: 33px 0 50px;

  @include media-breakpoint-up(md) {
    padding: 136px 0 161px;
  }

  .container {
    @media (min-width: 1600px) {
      width: 100%;
      max-width: unset;
    }
  }
  img {
    height: auto;
    max-width: 1440px;
    margin: 0 auto;
    display: block;
    transform: scale3d(1, 1, 1);
    transition: transform ease 0.4s;
  }
  a {
    display: block;
    position: relative;
    text-align: center;
    text-decoration: none;
    &:hover {
      img {
        transform: scale3d(1.05, 1.05, 1);
      }
    }
  }

  h1 {
    font-family: $font-family-serif;
    line-height: 1;
    margin: 0 auto;
    padding: 0;
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translateY(50%);
    font-weight: 700;
    text-align: center;
  }
}
</style>
