var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-row',{staticClass:"collection-teaser",class:{
    offset: _vm.offset
  },attrs:{"align-v":"center"}},[_c('b-col',{staticClass:"collection-teaser__visual",class:{
      'offset-2': _vm.offset && _vm.visualIsRight,
      right: _vm.visualIsRight,
      left: !_vm.visualIsRight
    },attrs:{"md":_vm.offset ? 4 : 6,"cols":_vm.offset ? 10 : 12}},[_c('base-link',{attrs:{"linkdata":_vm.link}},[_c('div',{staticClass:"img-wrapper",class:{ brand: _vm.logo }},[(_vm.logo)?_c('media-element',{staticClass:"logo",attrs:{"media":_vm.logo}}):_vm._e(),_vm._v(" "),_c('media-element',{staticClass:"teaser-image",attrs:{"media":_vm.visual}})],1)])],1),_vm._v(" "),_c('b-col',{staticClass:"collection-teaser__text",class:{
      'offset-2':
        (_vm.offset && _vm.sMd && _vm.visualIsRight) || (_vm.offset && !_vm.visualIsRight && !_vm.sMd)
    },attrs:{"md":_vm.offset ? 4 : 6,"cols":_vm.offset ? 10 : 12}},[_c('base-link',{attrs:{"linkdata":_vm.link}},[_c('h3',[_vm._v(_vm._s(_vm.headline))]),_vm._v(" "),_c('span',{staticClass:"btn-underlined"},[_vm._v(_vm._s(_vm.link.label))])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }