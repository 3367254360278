<template>
  <base-section class="section stories-overview-section">
    <b-container>
      <b-row v-if="fields.teaser_limit">
        <b-col cols="12">
          <h4 class="teaser-title">
            {{ fields.headline }}
          </h4>
        </b-col>
      </b-row>
      <b-row
        ><client-only>
          <masonry :cols="{ default: 3, 400: 1 }" :gutter="{ default: '0' }">
            <div
              v-for="(story, index) in relatedStories"
              :key="index"
              class="story-col"
            >
              <nuxt-link :to="story.link" class="story-teaser-wrapper">
                <div class="story-teaser">
                  <media-element
                    class="story-image"
                    :media="story.meta.meta_image"
                  />

                  <p class="headline md" v-html="story.title"></p>
                  <p
                    class="trunc-copy"
                    v-line-clamp:30="4"
                    v-html="story.meta.meta_description"
                  ></p>
                </div>
              </nuxt-link>
            </div>
          </masonry>
        </client-only>
      </b-row>
    </b-container>
  </base-section>
</template>

<script>
export default {
  components: {},
  props: {
    fields: Object
  },
  computed: {
    storageURL() {
      return '/storage/'
    },
    relatedStories() {
      if (this.fields.teaser_limit) {
        return this.fields.related_stories
      } else {
        return this.fields.stories
      }
    }
  }
}
</script>

<style lang="scss">
section.stories-overview-section {
  padding: 90px 0;
  display: block;
  height: auto;

  .teaser-title {
    padding-bottom: 16px;
  }

  /* BASE */
  .row {
    display: block;
  }

  /* STORY COLUMNS */
  .story-col {
    width: 100%;
    padding-bottom: 32px;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    @include media-breakpoint-up(xs) {
      // width: 33.33333%;
      padding-left: 0.5rem;
      padding-right: 0.5rem;
      padding-bottom: 64px;
    }
    .story-teaser-wrapper {
      text-decoration: none;
      display: inline-block;
      overflow: hidden;
      &:hover {
        .story-teaser {
          .story-image {
            img {
              transform: scale3d(1.05, 1.05, 1);
            }
          }
        }
      }
      .story-teaser {
        .story-image {
          width: 100%;
          display: block;
          img {
            transform: scale3d(1, 1, 1);
            transition: transform ease 0.4s;
          }
        }
        .headline {
          padding-top: 24px;
          margin-bottom: 1rem;
        }
        .trunc-copy {
          word-break: break-word !important ;
          margin-bottom: 0;
        }
      }
    }
  }
}
</style>
