<template>
  <base-section
    class="section category-cards-section"
    :class="{
      'has-slider': fields['has-mobile-slider'],
      spacer: fields.spacing.spacing
    }"
  >
    <b-container>
      <b-row class="headline-row">
        <b-col cols="auto">
          <h4 v-if="fields.headline" class="mt-4 mt-sm-0">
            {{ fields.headline }}
          </h4>
        </b-col>
      </b-row>
      <b-row>
        <div
          class="swiper-container"
          :class="{
            'ml-3': isMobile.matches && fields['has-mobile-slider']
          }"
          ref="swiper"
        >
          <div class="swiper-wrapper">
            <b-col
              v-for="(category, index) in fields.categories"
              :key="index"
              cols="6"
              :md="md"
              class="mb-2 mb-md-4 swipe-item"
              :class="{
                'pl-3 centered-card':
                  fields.categories.length % 2 !== 0 &&
                  fields.categories.length === index + 1 &&
                  isSm &&
                  swiperInstance === null
              }"
            >
              <category-card
                :title="category.title"
                :image="category.media"
                :links="category.links"
              />
            </b-col>
          </div>
        </div>
      </b-row>
    </b-container>
  </base-section>
</template>

<script>
import { Swiper } from 'swiper'

import CategoryCard from '~/components/CategoryCard'
export default {
  components: {
    CategoryCard
  },
  props: {
    fields: Object
  },
  data() {
    return {
      isMobile: false,
      swiperInstance: null,
      init: false,
      firstInit: true,
      swiperOptions: {
        slidesPerView: 1,
        slidesPerColumnFill: 'column',
        slideClass: 'swipe-item',
        spaceBetween: 0,
        simulateTouch: true,
        grabCursor: true,
        width: 300
      }
    }
  },
  computed: {
    md() {
      return this.fields.grid_size === '4col' ? 3 : 4
    },
    isSm() {
      return this.$breakpoints.sSm
    }
  },
  mounted() {
    this.$nextTick(function() {
      if (this.fields['has-mobile-slider']) {
        this.swiperMode()
      }
    })
    window.addEventListener('resize', this.swiperMode)
  },
  beforeDestroy() {
    if (this.fields['has-mobile-slider']) {
      window.removeEventListener('resize', this.swiperMode)
      if (this.swiperInstance) {
        this.swiperInstance.destroy(true, true)
        this.swiperInstance = null
      }
    }
  },
  methods: {
    swiperMode() {
      if (this.fields['has-mobile-slider']) {
        this.isMobile = window.matchMedia(
          '(min-width: 0px) and (max-width: 768px)'
        )
        const mobile = this.isMobile
        const tabletAndUp = window.matchMedia('(min-width: 769px)')
        const $swiper = this.$refs.swiper

        // Enable (for mobile)
        if (mobile.matches) {
          if (!this.init) {
            this.init = true
            // console.log('mobile', $swiper)
            this.swiperInstance = new Swiper($swiper, this.swiperOptions)
            this.swiperInstance.slideTo(this.routeSlideIndex, 0)
          }
        }

        // Disable (for tablet)
        else if (tabletAndUp.matches) {
          if (this.init) {
            this.init = false
            // console.log('> tablet', $swiper)
            if (this.swiperInstance) {
              this.swiperInstance.destroy(true, true)
              this.swiperInstance = null
            }
          } else if (this.firstInit === true) {
            this.init = false
            this.firstInit = false
            // console.log('> tablet & init', $swiper)
            if (this.swiperInstance) {
              this.swiperInstance.destroy(true, true)
              this.swiperInstance = null
            }
          }
        }
      }
    }
  }
}
</script>

<style lang="scss">
.category-cards-section {
  padding: 59px 0;

  &.spacer {
    padding: 120px 0 120px;
  }
  @include media-breakpoint-down(md) {
    .swipe-item:nth-child(even) {
      padding-left: 0.75rem !important;
    }
    .swipe-item:nth-child(odd) {
      padding-right: 0.75rem !important;
    }
  }

  .centered-card {
    transform: translateX(50%);
  }
  .swiper-container {
    width: 100%;
  }

  &.has-slider {
    .swipe-item {
      @include media-breakpoint-down(md) {
        padding-left: 0.5rem !important;
        padding-right: 0.5rem !important;
      }
    }
    .swiper-wrapper {
      @include media-breakpoint-up(sm) {
        display: flex;
        flex-wrap: wrap;
      }
      .swipe-item {
        @include media-breakpoint-down(xs) {
          flex: 0 0 300px !important;
          min-width: 300px;
        }
      }
    }
  }

  &:not(.has-slider) {
    .swiper-wrapper {
      display: flex;
      flex-wrap: wrap;
    }
  }
}
</style>
