<template>
  <base-section class="section shop-intro-section">
    <b-container>
      <b-row
        align-v="center"
        class="copy"
        :class="{ centered: fields.centered }"
      >
        <b-col class="mb-md-2 mb-xl-2" cols="12" sm="12" md="8" lg="6">
          <h4>{{ fields.headline }}</h4>
          <div v-html="fields.copy"></div>
        </b-col>
        <b-col
          v-if="fields.visual"
          class="image mb-0 mt-md-0 mb-md-8"
          cols="12"
          md="2"
          offset-md="1"
          lg="3"
          offset-lg="3"
        >
          <media-element :media="fields.visual" />
        </b-col>
      </b-row>
    </b-container>
  </base-section>
</template>

<script>
export default {
  props: {
    fields: Object
  }
}
</script>

<style lang="scss">
.shop-intro-section {
  padding: 1.3rem 0 0;
  .centered {
    @include media-breakpoint-up(md) {
      justify-content: center;
      text-align: center;
    }
  }
  div {
    h4 {
      line-height: 36px;
    }
  }
  div > p:last-child {
    margin-bottom: 0;
  }
  .image {
    img {
      display: block;
      height: auto;
      margin: 0 auto 120px;
      max-width: 147px;
      width: 100%;
      @include media-breakpoint-up(md) {
        margin: 0 0;
      }
    }
  }
}
</style>
