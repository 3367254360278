<template>
  <base-section class="section collection-teaser-section">
    <b-container>
      <collection-teaser
        v-for="(collection, i) in fields.collections"
        :key="collection.name"
        :headline="collection.headline"
        :visual="collection.visual"
        :logo="collection.logo"
        :link="collection.link"
        :offset="collection.offset"
        :visual-is-right="i % 2 === 0 ? false : true"
      />
    </b-container>
  </base-section>
</template>

<script>
import CollectionTeaser from '~/components/CollectionTeaser'
export default {
  components: {
    CollectionTeaser
  },
  props: {
    fields: {
      type: Object,
      default: () => {}
    }
  }
}
</script>

<style lang="scss">
.collection-teaser-section {
  padding: 42px 0 128px;
}
</style>
