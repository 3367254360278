<template>
  <base-section class="section copy-section">
    <b-container>
      <b-row align-h="center">
        <b-col cols="12" sm="12" md="8" lg="6" v-html="fields.copy"></b-col>
      </b-row>
    </b-container>
  </base-section>
</template>

<script>
export default {
  props: {
    fields: Object
  },
  data() {
    return {}
  }
}
</script>

<style lang="scss">
.copy-section {
  padding: 3rem 0;
  @include media-breakpoint-up(md) {
    padding: 3vw 0;
  }
  blockquote,
  blockquote p {
    font-family: $font-family-serif;
    font-size: 30px;
    line-height: 36px;
    letter-spacing: -0.86px;
    text-align: center;
  }

  h1,
  h2,
  h3,
  h4,
  h5 {
    &.special {
      font-size: 2.5rem;
      line-height: 36px;
    }
  }
}
</style>
