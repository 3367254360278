export default {
  head() {
    return {
      title: this.response.data.meta.meta_title
        ? this.response.data.meta.meta_title
        : this.response.data.title,
      meta: [
        { charset: 'utf-8' },
        { name: 'viewport', content: 'width=device-width, initial-scale=1' },
        // hid is used as unique identifier. Do not use `vmid` for it as it will not work
        {
          name: 'description',
          content: this.response.data.meta.meta_description
            ? this.response.data.meta.meta_description
            : this.$store.state.site.options.seo.default_meta_description
        },
        {
          property: 'og:locale',
          content: this.$store.$i18n.locale
        },
        {
          property: 'og:type',
          content: 'website'
        },
        {
          property: 'og:url',
          content: process.env.APP_URL + this.response.data.link
        },
        {
          property: 'og:title',
          content: this.response.data.meta.meta_title
            ? this.response.data.meta.meta_title
            : this.response.data.title
        },
        {
          property: 'og:description',
          content: this.response.data.meta.meta_description
            ? this.response.data.meta.meta_description
            : this.$store.state.site.options.seo.default_meta_description
        },
        {
          property: 'og:site_name',
          content: 'Lille Compagnie'
        },
        {
          property: 'og:image',
          content:
            this.response.data.meta.meta_image !== null
              ? process.env.APP_URL + this.response.data.meta.meta_image.url
              : process.env.APP_URL +
                this.$store.state.site.options.seo.default_meta_image.url
        },
        {
          name: 'twitter:card',
          content: 'summary'
        },
        {
          name: 'twitter:url',
          content: process.env.APP_URL + this.response.data.link
        },
        {
          name: 'twitter:title',
          content: this.response.data.meta.meta_title
            ? this.response.data.meta.meta_title
            : this.response.data.title
        },
        {
          name: 'twitter:description',
          content: this.response.data.meta.meta_description
            ? this.response.data.meta.meta_description
            : this.$store.state.site.options.seo.default_meta_description
        },
        {
          property: 'twitter:image',
          content:
            this.response.data.meta.meta_image !== null
              ? process.env.APP_URL + this.response.data.meta.meta_image.url
              : process.env.APP_URL +
                this.$store.state.site.options.seo.default_meta_image.url
        }
      ]
    }
  }
}
