<template>
  <div class="product-pagination">
    <arrow-left
      :class="{ active: localIndex !== 0 }"
      class="arrow arrow-left"
      @click="localIndex--"
    />
    <button
      v-for="(page, i) in pages"
      :key="i"
      @click="localIndex = i"
      :class="{ active: i === index }"
    >
      {{ page }}
    </button>
    <arrow-right
      :class="{ active: localIndex !== count - 1 }"
      class="arrow arrow-right"
      @click="localIndex++"
    />
  </div>
</template>

<script>
import arrowLeft from '~/assets/svg/pagination-arrow-left.svg?inline'
import arrowRight from '~/assets/svg/pagination-arrow-right.svg?inline'

export default {
  components: { arrowLeft, arrowRight },
  model: {
    prop: 'index',
    event: 'update'
  },
  props: {
    index: Number,
    count: Number
  },
  data() {
    return {
      localIndex: this.index
    }
  },
  computed: {
    pages() {
      const arr = []
      for (let i = 0; i < this.count; i++) {
        arr[i] = i + 1
      }
      return arr
    }
  },
  watch: {
    index(value) {
      this.localIndex = value
    },
    localIndex() {
      this.$emit('update', this.localIndex)
    },
    count(value) {
      this.localIndex = 0
    }
  }
}
</script>

<style lang="scss" scoped>
.product-pagination {
  width: 100%;
  text-align: center;
  padding: 80px 0 0 0;
  user-select: none;

  .arrow {
    display: inline-block;
    position: relative;
    top: -5px;
    width: 35px;
    margin: 0 15px;
    visibility: hidden;

    @include media-breakpoint-down(sm) {
      top: -3px;
      width: 30px;
    }

    @include media-breakpoint-down(xs) {
      top: 2px;
    }

    &-left {
      @include media-breakpoint-down(xs) {
        float: left;
      }
    }

    &-right {
      @include media-breakpoint-down(xs) {
        float: right;
      }
    }

    cursor: pointer;
    &.active {
      visibility: visible;
    }
  }

  button {
    border: none;
    background: transparent;
    font-size: 30px;
    font-weight: 400;
    color: #c2c2c2;
    outline: none;

    @include media-breakpoint-down(sm) {
      font-size: 24px;
    }
    &.active {
      color: #1c0f0c;
      cursor: default;
    }
  }
}
</style>
