var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('base-section',{staticClass:"section category-cards-section",class:{
    'has-slider': _vm.fields['has-mobile-slider'],
    spacer: _vm.fields.spacing.spacing
  }},[_c('b-container',[_c('b-row',{staticClass:"headline-row"},[_c('b-col',{attrs:{"cols":"auto"}},[(_vm.fields.headline)?_c('h4',{staticClass:"mt-4 mt-sm-0"},[_vm._v("\n          "+_vm._s(_vm.fields.headline)+"\n        ")]):_vm._e()])],1),_vm._v(" "),_c('b-row',[_c('div',{ref:"swiper",staticClass:"swiper-container",class:{
          'ml-3': _vm.isMobile.matches && _vm.fields['has-mobile-slider']
        }},[_c('div',{staticClass:"swiper-wrapper"},_vm._l((_vm.fields.categories),function(category,index){return _c('b-col',{key:index,staticClass:"mb-2 mb-md-4 swipe-item",class:{
              'pl-3 centered-card':
                _vm.fields.categories.length % 2 !== 0 &&
                _vm.fields.categories.length === index + 1 &&
                _vm.isSm &&
                _vm.swiperInstance === null
            },attrs:{"cols":"6","md":_vm.md}},[_c('category-card',{attrs:{"title":category.title,"image":category.media,"links":category.links}})],1)}),1)])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }