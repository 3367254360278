<template>
  <base-section
    class="section fullwidth-content-image-section"
    :id="fields.anchor_id.anchor_id"
  >
    <template v-if="fields.has_parallax">
      <div
        class="media-wrapper"
        ref="parallax"
        data-rellax-speed="-5"
        data-rellax-percentage=".5"
      >
        <media-element :media="fields.background" />
      </div>
    </template>
    <template v-else>
      <div class="media-wrapper">
        <media-element :media="fields.background" />
      </div>
    </template>
    <b-container fluid class="copy" v-show="fields.headline">
      <b-row align-h="center">
        <b-col cols="12" sm="8">
          <h2 :class="['h3', { black: fields.headline_color }]">
            {{ fields.headline }}
          </h2>
        </b-col>
      </b-row>
    </b-container>
  </base-section>
</template>

<script>
import Rellax from 'rellax'
export default {
  props: {
    fields: Object
  },
  data() {
    return {
      rellax: null
    }
  },
  computed: {
    lMd() {
      return this.$breakpoints.lMd
    }
  },
  watch: {
    lMd(newVal) {
      if (newVal && this.rellax === null) {
        if (this.fields.has_parallax) {
          this.createParallax()
        }
      } else if (this.fields.has_parallax && newVal) {
        this.destroyParallax()
      }
    }
  },
  mounted() {
    if (this.lMd) {
      if (this.fields.has_parallax) {
        this.createParallax()
      }
    }
  },
  beforeDestroy() {
    if (this.fields.has_parallax) {
      this.destroyParallax()
    }
  },
  methods: {
    createParallax() {
      this.rellax = new Rellax(this.$refs.parallax, {
        // wrapper: '.hero-section',
        // center: true
      })
    },
    destroyParallax() {
      if (this.rellax !== null) {
        this.rellax.destroy()
        this.rellax = null
      }
    }
  }
}
</script>

<style lang="scss">
.fullwidth-content-image-section {
  overflow: hidden;
  @include aspect-ratio(1, 1);
  @include media-breakpoint-up(sm) {
    @include aspect-ratio-max-height(1440, 500, 800px);
  }

  .media-wrapper {
    overflow: hidden;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    height: 100%;
    @include media-breakpoint-up(md) {
      height: 90vh;
      max-height: 1000px;
    }
    .media-element {
      img {
        display: block;
        height: 100%;
        object-fit: cover;
        position: absolute;
        top: 0;
        width: 100%;
      }
    }
  }

  .copy {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    h2 {
      color: white;
      text-align: center;
      &.black {
        color: $black;
      }
    }
  }
}
</style>
