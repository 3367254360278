<template>
  <base-section
    class="section teaser-section"
    :class="{
      spacer: fields.spacing.spacing
    }"
  >
    <b-container>
      <b-row align-v="center" align-h="center">
        <b-col cols="12" md="7" class="visual">
          <media-element :media="fields.visual" />
        </b-col>
        <b-col cols="12" md="4" offset-md="1">
          <h3>{{ fields.headline }}</h3>
          <p v-html="fields.copy"></p>
          <base-link
            :key="fields.button.label"
            :linkdata="fields.button"
            class="btn"
          >
            {{ fields.button.label }}
          </base-link>
        </b-col>
      </b-row>
    </b-container>
  </base-section>
</template>

<script>
export default {
  props: {
    fields: Object
  }
}
</script>

<style lang="scss">
.teaser-section {
  padding-top: 2rem;
  padding-bottom: 6rem;
  &.spacer {
    padding-top: 202px;
    padding-bottom: 213px;
  }
  p {
    line-height: 1.5;
  }
  h3 {
    line-height: calc(56 / 60);
  }
  .visual {
    margin-bottom: 32px;
    @include media-breakpoint-up(md) {
      margin-bottom: 0px;
    }
    img {
      height: auto;
      width: 100%;

      @include media-breakpoint-up(md) {
        min-height: 491px;
      }
    }
  }
}
</style>
