<template>
  <div class="color-picker">
    <div
      v-for="(color, i) in colors"
      :key="i"
      class="color-wrapper"
      @click="pickColor(color, i)"
    >
      <div class="color" :style="{ background: color.color }"></div>
    </div>
    <div
      class="highlight"
      :style="highlighter"
      v-show="selectedColor !== ''"
    ></div>
  </div>
</template>

<script>
export default {
  props: {
    colors: {
      type: Array,
      default: () => []
    },
    value: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      selectedColor: '',
      current: 0
    }
  },
  computed: {
    highlighter() {
      return `transform: translateX(${this.current * 1.4 * 16 +
        this.current * 16}px)`
    }
  },
  created() {
    if (this.colors.length > 0) {
      this.colors.find((color, i) => {
        if (color.value === this.value) {
          this.selectedColor = color
          this.current = i
        }
      })
    }
  },
  methods: {
    pickColor(color, i) {
      this.selectedColor = color.value
      this.current = i
      this.$emit('input', this.selectedColor)
    }
  }
}
</script>

<style lang="scss">
.color-picker {
  position: relative;
  .color-wrapper {
    display: inline-block;
    margin: 0 0.5rem;
    &:first-child {
      margin-left: 0;
    }
    &:last-child {
      margin-right: 0;
    }
  }
  .color {
    width: 1.4rem;
    height: 1.4rem;
    border-radius: 50%;
    border: 1px solid #e8e7e6;
    cursor: pointer;
  }
  .highlight {
    position: absolute;
    width: 1.4rem;
    height: 0.12rem;
    background: $text-color;
    left: 0;
    transition: 0.2s transform cubic-bezier(0.77, 0, 0.175, 1);
  }
}
</style>
