<template>
  <base-section class="section anchor-nav-with-content-section">
    <b-container>
      <b-row>
        <b-col
          cols="12"
          sm="3"
          class="nav-col"
          ref="contentWrapper"
          sticky-container
        >
          <div
            class="nav-wrapper"
            ref="leftContent"
            v-sticky="isDesktop"
            sticky-offset="stickyOffset"
          >
            <b-nav class="anchor-wrapper" v-b-scrollspy="stickyOffset.top + 20">
              <b-nav-item
                v-for="(anchor, index) in fields.datas"
                :key="index"
                :href="'#' + anchor.anchorid"
                >{{ anchor.headline }}</b-nav-item
              >
            </b-nav>
          </div>
        </b-col>
        <b-col cols="12" offset="0" offset-sm="1" sm="6" class="content-col">
          <div class="content-wrapper" id="nav-scroller" ref="rightContent">
            <div
              :id="cb.anchorid"
              class="copy-block"
              v-for="cb in fields.datas"
              :key="cb.id"
            >
              <h3>{{ cb.headline }}</h3>
              <div v-html="cb.copy"></div>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </base-section>
</template>

<script>
import { gsap, Power4 } from 'gsap'
import { ScrollToPlugin } from 'gsap/ScrollToPlugin'
if (process.client) {
  gsap.registerPlugin(ScrollToPlugin)
}
export default {
  props: {
    fields: Object
  },
  data() {
    return {
      stickyOffset: {
        top: 148
      },
      screenWidth: 0
    }
  },
  computed: {
    isDesktop() {
      return this.screenWidth > 768
    }
  },
  mounted() {
    window.addEventListener('resize', this.onResize)
    this.onResize()
  },
  destroyed() {
    window.removeEventListener('resize', this.onResize)
  },
  methods: {
    onResize(evt) {
      this.screenWidth = screen.width
    },
    // Convenience method to scroll a heading into view.
    // Not required for scrollspy to work
    scrollIntoView(evt) {
      const href = evt.target.getAttribute('href')
      const el = href ? document.querySelector(href) : null
      let offset = el.offsetTop
      if (this.isMobile) {
        offset = el.offsetTop + 200
      } else {
        offset = el.offsetTop - 12
      }

      if (el) {
        gsap.to(window, 0.7, {
          scrollTo: {
            y: offset,
            autoKill: true
          },
          ease: Power4.easeOut
        })
        // document.querySelector('.content').scrollTop = el.offsetTop
      }
    }
  }
}
</script>

<style lang="scss">
.anchor-nav-with-content-section {
  padding-top: 30px;
  padding-bottom: 50px;
  @include media-breakpoint-up(sm) {
    padding-top: 60px;
    padding-bottom: 80px;
  }
  .nav-col {
    &:after {
      content: '';
    }
    .nav-wrapper {
      .anchor-wrapper {
        margin-left: 0;
        margin-bottom: 64px;
        @include media-breakpoint-up(sm) {
          margin-bottom: 0;
        }
        .nav-item {
          display: block;
          width: 100%;
          a {
            display: inline-block;
            font-weight: 500;
            color: #a4a4a4;
            text-decoration: none;
            letter-spacing: 0.5px;
            transition: color ease 0.4s;
            padding: 0;
            margin: 0.5rem 0;
            &:hover,
            &.active {
              color: $black;
            }
          }
        }
      }
    }
  }

  .content-col {
    position: relative;
    padding-top: 12px;
    &:before {
      position: absolute;
      content: '';
      display: none;
      height: 100%;
      width: 1px;
      background-color: #dcdcdc;

      @include media-breakpoint-up(sm) {
        display: block;
        margin-left: calc(-8.33333% - 1rem);
      }
      @include media-breakpoint-up(md) {
        margin-left: calc(-16.66666% - 1rem);
      }
    }
    .copy-block {
      padding-bottom: 50px;
      p {
        a {
          text-decoration: underline;
        }
      }
      @include media-breakpoint-up(sm) {
        padding-bottom: 100px;
      }
    }
  }
}
</style>
