<template>
  <main>
    <component
      :is="section.type"
      v-for="(section, index) in page.data.layout"
      :key="index"
      :fields="section.fields"
    />
  </main>
</template>

<script>
import ParallaxHeroSection from '~/sections/ParallaxHeroSection'
import StickyHeaderSection from '~/sections/StickyHeaderSection'
import CategoryCardsSection from '~/sections/CategoryCardsSection'
import HeroSection from '~/sections/HeroSection'
import AnchorNavWithContentSection from '~/sections/AnchorNavWithContentSection'
import BigTeaserSection from '~/sections/BigTeaserSection'
import CollectionTeaserSection from '~/sections/CollectionTeaserSection'
import CopySection from '~/sections/CopySection'
import ImageGridSection from '~/sections/ImageGridSection'
import LookSection from '~/sections/LookSection'
import ProductGridSection from '~/sections/ProductGridSection'
import ProductSection from '~/sections/ProductSection'
import RelatedTeaserSection from '~/sections/RelatedTeaserSection'
import ShopIntroSection from '~/sections/ShopIntroSection'
import SliderTeaserSection from '~/sections/SliderTeaserSection'
import StoriesOverviewSection from '~/sections/StoriesOverviewSection'
import TeaserSection from '~/sections/TeaserSection'
import TwoColImageTextSection from '~/sections/TwoColImageTextSection'
import BreadcrumpsSection from '~/sections/BreadcrumpsSection'
import FullwidthContentImage from '~/sections/FullwidthContentImageParallaxSection'

export default {
  components: {
    parallaxhero: ParallaxHeroSection,
    stickyheader: StickyHeaderSection,
    categorycards: CategoryCardsSection,
    hero: HeroSection,
    fullwidthcontentimageparallax: FullwidthContentImage,
    anchornavwithcontent: AnchorNavWithContentSection,
    bigteaser: BigTeaserSection,
    collectionteaser: CollectionTeaserSection,
    copy: CopySection,
    imagegrid: ImageGridSection,
    look: LookSection,
    productgrid: ProductGridSection,
    product: ProductSection,
    relatedteaser: RelatedTeaserSection,
    shopintro: ShopIntroSection,
    sliderteaser: SliderTeaserSection,
    storiesoverview: StoriesOverviewSection,
    teaser: TeaserSection,
    twocolimagetext: TwoColImageTextSection,
    breadcrumbs: BreadcrumpsSection
  },
  props: {
    page: {
      type: Object,
      default: () => {}
    }
  },
  mounted() {
    this.$root.$emit('scrollOnMount')
  }
}
</script>

<style lang="scss" scoped>
main {
  min-height: calc(100vh - 74px);
}
</style>
