<template>
  <base-section class="section look-section">
    <b-container>
      <b-row>
        <b-col cols="12" md="6">
          <media-element
            :media="fields.visual"
            class="look-image"
            v-sticky="!isMobile"
            sticky-offset="lookSticky"
            sticky-z-index="9"
          />
        </b-col>
        <b-col cols="12" md="4" offset-md="1">
          <h4>{{ fields.headline }}</h4>
          <div v-html="fields.copy" />
          <div class="look-products">
            <b-row>
              <b-col
                cols="6"
                md="12"
                v-for="product in fields.collection.products"
                :key="product.id"
                class="py-2 px-1 py-sm-3 px-md-2"
              >
                <product-card :product="product" />
              </b-col>
            </b-row>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </base-section>
</template>

<script>
import ProductCard from '~/components/ProductCard'
export default {
  components: {
    ProductCard
  },
  props: {
    fields: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      lookSticky: {
        top: 24 + 74
      }
    }
  },
  computed: {
    isMobile() {
      return this.$breakpoints.sSm
    }
  }
}
</script>

<style lang="scss">
.look-section {
  padding: 24px 0 128px;
  .look-image {
    height: auto;
    margin-bottom: 56px;
    max-width: 100%;
    @include media-breakpoint-up(md) {
      margin-bottom: 0;
    }
  }
  .look-products {
    margin-top: 56px;
    @include media-breakpoint-up(md) {
      margin-top: 42px;
    }
    .product-card {
      margin-bottom: 40px;
    }
  }
}
</style>
