<template>
  <base-section class="section image-grid-section">
    <b-container>
      <b-row>
        <b-col
          v-for="item in images"
          :key="item.id"
          cols="12"
          :md="getColumns"
          class="item"
        >
          <nuxt-link :to="item.link.url">
            <img :src="item.image" />
            <span>{{ item.caption }}</span>
          </nuxt-link>
        </b-col>
      </b-row>
    </b-container>
  </base-section>
</template>

<script>
export default {
  data() {
    return {
      columns: 4,
      images: [
        {
          image: '/images/collections/img1.jpg',
          caption: 'Linus',
          link: {
            url: '/look/'
          }
        },
        {
          image: '/images/collections/img2.jpg',
          caption: 'Linus',
          link: {
            url: '/look/'
          }
        },
        {
          image: '/images/collections/img3.jpg',
          caption: 'Linus',
          link: {
            url: '/look/'
          }
        },
        {
          image: '/images/collections/img2.jpg',
          caption: 'Linus',
          link: {
            url: '/look/'
          }
        }
      ]
    }
  },
  computed: {
    getColumns() {
      return 12 / this.columns
    }
  }
}
</script>

<style lang="scss">
.image-grid-section {
  padding: 8vw 0;
  .item {
    margin-bottom: 32px;
    // @include media-breakpoint-up(md) {
    //   margin-bottom: 0;
    // }
    a {
      display: block;
      img {
        height: auto;
        width: 100%;
      }
      span {
        display: block;
        font-weight: 500;
        margin-top: 12px;
      }
    }
  }
}
</style>
