<template>
  <!-- Color Swatches -->
  <div v-if="inputType === 'color'" :index="index" :class="inputType">
    <color-picker
      v-model="localValue"
      :colors="colorOptions"
      @change="onChange"
    />
  </div>
  <!-- Button Group -->
  <b-button-group
    v-else
    :name="name"
    :index="index"
    @change="onChange"
    :class="{
      select: inputType === 'select',
      buttons: inputType !== 'select'
    }"
  >
    <div
      class="placeholder"
      v-if="inputType === 'select'"
      @click="toggleOptions()"
    >
      {{ localValue }}
    </div>
    <div class="option-values" :class="{ active: active_options }">
      <b-button
        v-for="(item, sindex) in options"
        :key="sindex"
        @click="onChangeClick(item), toggleOptions()"
        :class="{ selected: item.value == localValue }"
      >
        {{ item.value }}
      </b-button>
    </div>
  </b-button-group>
</template>

<script>
import ColorPicker from '~/components/product/ColorPicker'
export default {
  components: {
    ColorPicker
  },
  model: {
    prop: 'value',
    event: 'input'
  },
  props: {
    name: String,
    value: String,
    index: Number,
    options: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      active_options: false,
      localValue: this.value
    }
  },
  computed: {
    inputType() {
      if (
        this.$store.state.filter.optionsMatches.color.includes(
          this.name.toLowerCase()
        ) &&
        this.showColorSwatches
      ) {
        return 'color'
      } else if (
        this.$store.state.filter.optionsMatches.size.includes(
          this.name.toLowerCase()
        ) &&
        this.hasCompactSizes
      ) {
        return 'group'
      } else {
        return 'select'
      }
    },
    hasCompactSizes() {
      let compact = true
      this.options.forEach(function callback(item) {
        if (item.length >= 40) {
          compact = false
        }
      })
      return compact
    },
    showColorSwatches() {
      let foundAllColors = true
      this.options.forEach((item, index) => {
        const cColor = '#000000'
        if (!cColor) {
          foundAllColors = false
        }
      })
      return foundAllColors
    },
    colorOptions() {
      const tmpOptions = []
      this.options.forEach((item) => {
        tmpOptions.push({
          color: '#000000',
          value: item
        })
      })
      return tmpOptions
    }
  },
  watch: {
    value(newVal) {
      this.localValue = newVal
    },
    localValue() {
      this.$emit('input', this.localValue)
    }
  },
  methods: {
    toggleOptions() {
      this.active_options = !this.active_options
    },
    onChangeClick(newVal) {
      this.localValue = newVal.value
      this.$nextTick(() => {
        this.$emit('change', this.localValue)
      })
    },
    onChange(e) {
      this.$nextTick(() => {
        this.$emit('change', this.localValue)
      })
    }
  }
}
</script>

<style lang="scss" scoped>
$borderColor: #ccc;
.btn-group.select {
  display: block;
  .btn {
    background: transparent;
    border: 0;
    box-shadow: none;
    color: inherit;
    display: block;
    font-weight: inherit;
    line-height: 1.41;
    margin: 0;
    min-width: 0;
    padding: 0;
    user-select: none;
  }
  .color-swatch {
    border: 1px solid #ccc;
    border-radius: 50%;
    display: inline-block;
    margin-right: 8px;
    transform: translateY(-1px);
    height: 20px;
    width: 20px;
    vertical-align: text-bottom;
  }
  .placeholder {
    background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='17px' height='8px' viewBox='0 0 17 8' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3C!-- Generator: Sketch 63.1 (92452) - https://sketch.com --%3E%3Ctitle%3Eicon/plus_small copy 7%3C/title%3E%3Cdesc%3ECreated with Sketch.%3C/desc%3E%3Cg id='Layout' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='Artikel_Detail-Copy-3' transform='translate(-1219.000000, -339.000000)' stroke='%23979797'%3E%3Cg id='icon/plus_small' transform='translate(1220.000000, 335.000000)'%3E%3Cpolyline id='Path' transform='translate(7.500000, 4.500000) rotate(-45.000000) translate(-7.500000, -4.500000) ' points='12.5 9.5 2.5 9.5 2.5 -0.5'%3E%3C/polyline%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: right center;
    cursor: pointer;
    padding-right: 24px;
    padding-top: 6px;
    user-select: none;
  }
  .option-values {
    display: none;
    margin-top: 6px;
    &.active {
      display: block;
    }
    .btn {
      margin: 2px 0;
      padding: 4px 0;
    }
  }
}

.btn-group.buttons {
  display: block;
  .option-values {
    border-left: 1px solid $borderColor;
    display: flex;
    flex-wrap: wrap;
    .btn {
      font-size: 13px;
      background: white;
      border: none;
      border-right: 1px solid $borderColor;
      border-bottom: 1px solid $borderColor;
      box-sizing: border-box;
      color: inherit;
      line-height: 1.41;
      min-width: 0;
      padding: 10px 0 6px 0;
      text-align: center;
      transition: color 0.2s, background-color 0.2s;
      width: 33.333%;
      outline: none !important;
      box-shadow: none !important;

      &:hover {
        color: $borderColor !important;
      }
      &.selected {
        background: black;
        color: white;
      }
      &:nth-child(-n + 3) {
        border-top: 1px solid $borderColor;
      }
    }
  }
}
</style>
