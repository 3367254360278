<template>
  <base-section class="section parallax-hero-section" ref="parallaxWrapper">
    <div
      class="parallax-hero-image-wrapper"
      ref="imageWrapper"
      data-rellax-speed="-10"
      :class="{ fixed: !lMd }"
    >
      <media-element :media="fields.background" />
    </div>
    <Logo v-if="fields.logo" class="hero-logo" />
  </base-section>
</template>

<script>
import Rellax from 'rellax'
import Logo from '~/assets/svg/Lille_Compagnie_Typogram_signet.svg?inline'

export default {
  components: {
    Logo
  },
  props: {
    fields: Object
  },
  data() {
    return {
      rellax: null
    }
  },
  computed: {
    lMd() {
      return this.$breakpoints.lMd
    }
  },
  watch: {
    lMd(newVal) {
      if (newVal && this.rellax === null) {
        this.createParallax()
      } else {
        this.destroyParallax()
      }
    }
  },
  mounted() {
    this.$nextTick(function() {
      if (this.lMd) {
        this.createParallax()
      } else {
        const cHeight = document.documentElement.clientHeight
        document.documentElement.style.setProperty('--cHeight', cHeight + 'px')
      }
    })
  },
  beforeDestroy() {
    this.destroyParallax()
  },
  methods: {
    createParallax() {
      this.rellax = new Rellax(this.$refs.imageWrapper)
    },
    destroyParallax() {
      if (this.rellax !== null) {
        this.rellax.destroy()
        this.rellax = null
      }
    }
  }
}
</script>

<style lang="scss">
.parallax-hero-section {
  width: 100vw;
  position: relative;
  z-index: -1;
  height: calc(var(--cHeight) - 52px);
  // height: 600px;
  @include media-breakpoint-up(sm) {
    height: 80vh;
    max-height: 50vw;
  }

  .parallax-hero-image-wrapper {
    will-change: transform;
    position: relative;
    width: 100%;
    height: calc(var(--cHeight) - 52px);

    @include media-breakpoint-up(sm) {
      height: 100%;
    }

    .media-element {
      img {
        height: 100%;
      }
    }

    &.fixed {
      position: fixed;
    }
  }

  .hero-logo {
    position: fixed;
    top: 42px;
    left: 50%;
    transform: translate(-50%, 0);
    fill: #fff;
    width: 200px;
    transition: opacity $shift-animation-easing $shift-animation-duration,
      transform $shift-animation-easing $shift-animation-duration;

    @include media-breakpoint-up(sm) {
      width: 240px;
    }

    .activeOffset & {
      transform: translate(-50%, 50px);
    }

    .content-shifted & {
      opacity: 0;
      pointer-events: none;
    }
  }
}
</style>
