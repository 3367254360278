<template>
  <base-section
    :class="['two-col-image-text-section', { isquote: fields.isquote }]"
  >
    <b-container>
      <b-row class="image-text-row" v-win-resize="onResize">
        <b-col
          cols="12"
          sm="5"
          :class="['image-col', { noimg: !fields.has_image }]"
          order="2"
          order-sm="1"
          sticky-container
        >
          <template v-if="fields.has_image">
            <figure
              role="group"
              ref="leftContent"
              v-sticky="has_difference"
              sticky-offset="stickyOffset"
              sticky-z-index="3"
            >
              <media-element
                :media="fields.col_image"
                :style="imgAspectRatio(fields.col_image)"
              />
              <figcaption
                class="image-caption"
                v-if="fields.image_caption"
                v-html="fields.image_caption"
              ></figcaption>
            </figure>
          </template>
          <template v-else> <div></div> </template>
        </b-col>
        <b-col
          cols="12"
          sm="6"
          offset-sm="1"
          class="text-col"
          order="1"
          order-sm="2"
          sticky-container
        >
          <div
            ref="rightContent"
            v-sticky="has_difference"
            sticky-offset="stickyOffset"
            sticky-z-index="3"
          >
            <div class="copy-wrapper">
              <h3 v-if="fields.headline">{{ fields.headline }}</h3>
              <article
                v-html="fields.copy"
                :class="{ isquote: fields.isquote }"
              ></article>
              <div class="clearfix"></div>
            </div>
            <div class="clearfix"></div>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </base-section>
</template>

<script>
export default {
  props: {
    fields: Object
  },
  data() {
    return {
      stickyOffset: {
        top: 145
      },
      has_difference: false
    }
  },
  computed: {
    isMobile() {
      return this.$breakpoints.sMd
    }
    // stickLeft() {
    //   return (
    //     this.$refs.leftContent &&
    //     this.$refs.rightContent &&
    //     this.$refs.leftContent.clientHeight >
    //       this.$refs.rightContent.clientHeight &&
    //     this.has_difference &&
    //     !this.isMobile
    //   )
    // },
    // stickRight() {
    //   return (
    //     this.$refs.leftContent &&
    //     this.$refs.rightContent &&
    //     this.$refs.rightContent.clientHeight >
    //       this.$refs.leftContent.clientHeight &&
    //     this.has_difference &&
    //     !this.isMobile
    //   )
    // }
  },
  mounted() {
    this.$nextTick(function() {
      this.isMounted = true
      this.onResize()
    })
  },
  methods: {
    imgAspectRatio(visual) {
      if (visual.type === 'image') {
        return {
          'padding-top': (visual.image.height / visual.image.width) * 100 + '%'
        }
      }
    },
    onResize() {
      let diff = 0
      if (this.$refs.leftContent && this.$refs.rightContent) {
        diff = Math.abs(
          this.$refs.leftContent.clientHeight -
            this.$refs.rightContent.clientHeight
        )
        this.has_difference = diff > 100
      }
    }
  }
}
</script>

<style lang="scss">
section.two-col-image-text-section {
  padding-top: 80px;
  padding-bottom: 60px;
  &.isquote {
    padding-top: 0;
    padding-bottom: 0;
  }
  .image-text-row {
    padding-bottom: 30px;
    @include media-breakpoint-up(sm) {
      padding-bottom: 60px;
    }
    .image-col {
      &.noimg {
        height: 1px;
      }
      figure {
        margin: 0;
        padding-bottom: 1rem;
        .media-element {
          position: relative;
          width: 100%;
          background-color: #f3f3f3;
          img {
            display: block;
            width: 100%;
            position: absolute;
            top: 0;
            height: 100%;
          }
        }
      }
      .image-caption {
        font-family: $font-family-serif;
        padding-top: 8px;
        @include media-breakpoint-up(sm) {
          padding-top: 16px;
        }
      }
    }

    .text-col {
      .copy-wrapper {
        h1,
        h2,
        h3,
        h4,
        h5 {
          margin-bottom: 0;
          padding-bottom: 24px;
        }
        p {
          margin-bottom: 0;
          padding-bottom: 1.5em;
        }
        article {
          &.isquote {
            p {
              font-family: $font-family-serif;
              text-align: center;
              font-size: 24px;
              letter-spacing: -0.69px;
              line-height: 28px;
              @include media-breakpoint-up(sm) {
                font-size: 30px;
                line-height: 36px;
                letter-spacing: -0.86px;
              }
            }
          }
        }
      }
    }
  }
}
</style>
