<template>
  <div class="quickbuy" :class="{ visible: visible }">
    <div class="backdrop" />
    <div class="wrapper" :class="{ expanded: add2cartVisible }">
      <transition mode="out-in">
        <div class="teaser" v-if="!add2cartVisible" key="teaser">
          <div class="button" @click="add2cartVisible = true">
            {{ $t('shop.quickBuy') }}
          </div>
        </div>

        <div class="add-to-cart-wrapper" v-else key="add2cart">
          <div class="close-btn" @click="add2cartVisible = false" />
          <div class="content">
            <div
              class="option-group"
              v-for="(prop, index) in availibleOptions"
              :key="prop.name"
            >
              <template v-if="prop.name !== 'Color' && prop.values.length > 1">
                <hr />
                <div class="options">
                  <div class="option-name label" :class="prop.name">
                    {{ prop.name }}
                  </div>
                  <product-option-quick-buy
                    :index="index"
                    v-model="selectedOptions[prop.name]"
                    :name="prop.name"
                    size="sm"
                    :options="prop.values"
                  />
                </div>
              </template>
            </div>
          </div>
          <div
            class="add-to-cart-btn"
            :class="{ clickable: selectedOptions }"
            @click="addToCart"
          >
            <div class="button">
              {{ $t('shop.add') }}
            </div>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import ProductOptionQuickBuy from '~/components/product/ProductOptionQuickBuy'
export default {
  components: {
    ProductOptionQuickBuy
  },
  props: {
    product: {
      type: Object,
      required: true
    },
    visible: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      selectedOptions: null,
      add2cartVisible: false
    }
  },
  computed: {
    selectedVariant() {
      const entries = Object.entries(this.selectedOptions)
      const capsEntries = entries.map((entry) => [
        entry[0][0].toUpperCase() + entry[0].slice(1),
        entry[1]
      ])
      const capsSelectedOptions = Object.fromEntries(capsEntries)
      return this.$shopify.product.helpers.variantForOptions(
        this.product,
        capsSelectedOptions
      )
    },
    availibleOptions() {
      const options = this._.filter(
        this._.cloneDeep(this.product.options),
        (item) => {
          item.values = this._.filter(item.values, (vitem) => {
            let availible = false
            this.product.variants.forEach((variant) => {
              if (variant.available) {
                variant.selectedOptions.forEach((option) => {
                  if (
                    option.name === item.name &&
                    option.value === vitem.value
                  ) {
                    availible = true
                  }
                })
              }
            })
            return availible
          })
          return true
        }
      )
      return options
    }
  },
  watch: {
    visible(newVal) {
      const _this = this
      if (!newVal) {
        setTimeout(() => {
          _this.add2cartVisible = false
        }, 125)
      }
    }
  },
  created() {
    // const options = {}
    // for (const [key, value] of Object.entries(this.product.filter)) {
    //   options[key] = Array.isArray(value) ? value[0] : value
    // }
    // this.selectedOptions = options

    // const availVariants = this._.filter(this.product.variants, (item) => {
    //   return item.availible
    // })

    let vIndex = -1
    this.product.variants.forEach((element, index) => {
      if (vIndex === -1 && element.available) {
        vIndex = index
      }
    })

    this.selectedOptions = this.product.variants[vIndex].selectedOptions.reduce(
      (acc, cur) => ({ ...acc, [cur.name]: cur.value }),
      {}
    )
  },
  methods: {
    addToCart() {
      this.$store.commit('ui/openCart')
      const lineItemsToAdd = [
        {
          variantId: this.selectedVariant.id,
          quantity: 1
        }
      ]
      this.$store.dispatch('shop/addLineItems', lineItemsToAdd)
    }
  }
}
</script>

<style lang="scss">
.quickbuy {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 10;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  transition: 0.125s all cubic-bezier(0.55, 0.085, 0.68, 0.53);
  padding: 1rem 0.75rem;

  .backdrop {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 182px;
    background: rgb(0, 0, 0);
    background: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.15) 0%,
      rgba(0, 0, 0, 0) 100%
    );
    z-index: 11;
    pointer-events: none;
  }

  .wrapper {
    position: relative;
    // height: 48px;
    background: #fff;
    // transition: 0.25s all cubic-bezier(0.77, 0, 0.175, 1);
    z-index: 15;
    cursor: auto;
    pointer-events: auto;
    overflow: hidden;

    .button {
      position: relative;
      top: 3px;
      padding: 0.8rem 0;
      margin: 0 auto;
      width: 100%;
      font-weight: 300;
      background: #fff;
      text-align: center;
      cursor: pointer;
    }
    .teaser {
      position: relative;
    }

    .add-to-cart-wrapper {
      position: relative;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: flex-start;
      padding: 0 32px;
      align-items: flex-end;

      .content {
        position: relative;
        width: 100%;
        margin-top: 32px;
        margin-bottom: 65px;

        hr {
          margin: 16px 0;
        }

        .options {
          display: grid;
          grid-template-columns: 60px auto;

          .color-picker {
            padding-top: 3px;
          }

          .label {
            font-weight: 500;
            padding-top: 6px;

            &.size {
              padding-top: 10px;
            }
          }
        }
      }

      .close-btn {
        cursor: pointer;
        position: absolute;
        right: 2rem;
        top: 1.25rem;
        width: 12px;
        height: 12px;
        opacity: 0.5;
        z-index: 20;

        &:hover {
          opacity: 1;
        }
        &:before,
        &:after {
          position: absolute;
          left: 6px;
          content: ' ';
          height: 12px;
          width: 0.08rem;
          background-color: #333;
        }
        &:before {
          transform: rotate(45deg);
        }
        &:after {
          transform: rotate(-45deg);
        }
      }

      .add-to-cart-btn {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        .button {
          position: static;
          background: #b1b1b1;
          color: #fff;
          padding: 1rem 0 0.8rem;
          cursor: not-allowed;
          transition: 0.2s all ease-in-out;
        }

        &.clickable {
          .button {
            background: #1c0f0c;
            cursor: pointer;
          }
        }
      }
    }
  }

  &.visible {
    opacity: 1;
    visibility: visible;
  }

  .v-leave-active,
  .v-enter-active {
    transition: all 0.25s cubic-bezier(0.785, 0.135, 0.15, 0.86);
    max-height: 425px;
  }
  .v-leave-to,
  .v-enter {
    opacity: 0;
    max-height: 46px;
  }
}
</style>
