<template>
  <b-row
    align-v="center"
    class="collection-teaser"
    :class="{
      offset: offset
    }"
  >
    <b-col
      class="collection-teaser__visual"
      :md="offset ? 4 : 6"
      :cols="offset ? 10 : 12"
      :class="{
        'offset-2': offset && visualIsRight,
        right: visualIsRight,
        left: !visualIsRight
      }"
    >
      <base-link :linkdata="link">
        <div class="img-wrapper" :class="{ brand: logo }">
          <media-element :media="logo" v-if="logo" class="logo" />
          <media-element :media="visual" class="teaser-image" />
        </div>
      </base-link>
    </b-col>
    <b-col
      :md="offset ? 4 : 6"
      :cols="offset ? 10 : 12"
      :class="{
        'offset-2':
          (offset && sMd && visualIsRight) || (offset && !visualIsRight && !sMd)
      }"
      class="collection-teaser__text"
    >
      <base-link :linkdata="link">
        <h3>{{ headline }}</h3>
        <span class="btn-underlined">{{ link.label }}</span>
      </base-link>
    </b-col>
  </b-row>
</template>

<script>
export default {
  props: {
    visual: {
      type: Object,
      required: true
    },
    logo: {
      type: Object,
      default: () => {}
    },
    link: {
      type: Object,
      default: () => {}
    },
    headline: {
      type: String,
      required: true
    },
    offset: {
      type: Boolean,
      default: false
    },
    visualIsRight: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    sMd() {
      return this.$breakpoints.sMd // smaller than mid
    }
  }
}
</script>

<style lang="scss">
.collection-teaser {
  margin-bottom: 56px;

  @include media-breakpoint-up(md) {
    justify-content: center;
    margin-bottom: 170px;
  }
  &:nth-child(even) {
    @include media-breakpoint-down(md) {
      justify-content: end;
    }
    flex-direction: row-reverse;
  }

  &:last-child {
    margin-bottom: 0;
  }
  &__visual {
    position: relative;
    @include media-breakpoint-down(md) {
      margin-bottom: 24px;
    }

    a:hover {
      .teaser-image img {
        transform: scale3d(1.05, 1.05, 1);
      }
    }

    .img-wrapper {
      position: relative;
      .teaser-image {
        height: auto;
        max-width: 100%;
        img {
          transform: scale3d(1, 1, 1);
          transition: transform ease 0.4s;
        }
      }
    }
    .logo {
      margin: 0 auto;
      position: absolute;
      bottom: 40px;
      left: 0;
      right: 0;
      height: auto;
      max-width: 200px;
      width: 100%;
      z-index: 5;
    }
    &.offset-2 {
      @media (min-width: 1024px) and (max-width: 1200px) {
        margin-left: 0;
      }
    }
    &.right.offset-2 {
      @media (min-width: 1024px) and (max-width: 1200px) {
        margin-right: 8.333333%;
      }
    }

    &.left {
      .offset & {
        @media (min-width: 1024px) and (max-width: 1200px) {
          margin-right: 16.66667%;
        }
      }
    }
  }
  &__text {
    text-align: center;
    margin-bottom: 5rem;

    a {
      h3 {
        text-decoration: none;
      }
      &:hover {
        text-decoration: none;
        h3 {
          text-decoration: none;
        }
      }
    }

    &.offset-2 {
      @media (min-width: 1024px) and (max-width: 1200px) {
        margin-right: 16.66667%;
        margin-left: 8.333333%;
      }
    }
  }
}
</style>
